import { adminIfoRef, ridesRef, userRef } from "config/firebase";
import moment from "moment";
import { toast } from "react-toastify";
import { create } from "zustand";

type ReportsStore = {
  reportData: ReportData | null;
  isLoading: boolean;
  generateReport: (month: string, year: string) => Promise<void>;
};

export const useReportsStore = create<ReportsStore>((set) => ({
  reportData: null,
  isLoading: false,

  generateReport: async (month: string, year: string) => {
    set({ isLoading: true });

    const initialDate = moment()
      .year(Number(year))
      .month(Number(month))
      .startOf("month")
      .toDate();
    const finalDate = moment()
      .year(Number(year))
      .month(Number(month))
      .endOf("month")
      .toDate();

    try {
      // get all users created in the selected month
      const { docs: users } = await userRef
        .where("created_at", ">=", initialDate)
        .where("created_at", "<=", finalDate)
        .get();
      // get rides
      const { docs } = await ridesRef
        .where("created_at", ">=", initialDate)
        .where("created_at", "<=", finalDate)
        .get();

      let totalRides = 0;
      let totalFinishedRides = 0;
      let totalCancelledRides = 0;
      let totalGains = 0;
      let totalCarRides = 0;
      let totalMotoRides = 0;
      let totalCarFinished = 0;
      let totalMotoFinished = 0;
      let totalCarCancelled = 0;
      let totalMotoCancelled = 0;
      let totalCarCancelledPilot = 0;
      let totalMotoCancelledPilot = 0;
      let totalCarGains = 0;
      let totalMotoGains = 0;
      let rechargesAmount = 0;
      let carRecharges = 0;
      let motoRecharges = 0;
      let carRechargesAmount = 0;
      let motoRechargesAmount = 0;
      const payModeCount = { money: 0, card: 0, pix: 0 };

      docs.forEach((doc) => {
        const data = doc.data() as RideData;
        totalRides += 1;

        if (data.status === "CANCELLED") {
          totalCancelledRides += 1;
          if (data.type === "car") {
            totalCarCancelled += 1;
          } else {
            totalMotoCancelled += 1;
          }
        }

        if (data.status === "CANCELLED_PILOT") {
          if (data.type === "car") {
            totalCarCancelledPilot += 1;
          } else {
            totalMotoCancelledPilot += 1;
          }
        }

        if (data.status === "END") {
          totalFinishedRides += 1;
          totalGains += data.ride_cost;
          if (data.pay_mode === "money") {
            payModeCount.money += 1;
          } else if (data.pay_mode === "card") {
            payModeCount.card += 1;
          } else {
            payModeCount.pix += 1;
          }
          if (data.type === "car") {
            totalCarFinished += 1;
            totalCarGains += data.ride_cost;
          } else {
            totalMotoFinished += 1;
            totalMotoGains += data.ride_cost;
          }
        }

        if (data.type === "car") {
          totalCarRides += 1;
        } else {
          totalMotoRides += 1;
        }
      });

      const { docs: recharges } = await adminIfoRef
        .collection("recharge_history")
        .where("date", ">=", initialDate)
        .where("date", "<=", finalDate)
        .get();

      recharges.forEach((doc) => {
        const recharge = doc.data() as Recharge;
        if (recharge.status !== "PAID") return;
        rechargesAmount += recharge.amount;

        if (recharge.type === "car") {
          carRechargesAmount += recharge.amount;
          carRecharges++;
        } else {
          motoRechargesAmount += recharge.amount;
          motoRecharges++;
        }
      });

      const reportData = {
        newUsers: users.length,
        totalRides,
        totalFinishedRides,
        totalCancelledRides,
        totalGains,
        totalCarRides,
        totalMotoRides,
        totalCarFinished,
        totalMotoFinished,
        totalCarCancelled,
        totalMotoCancelled,
        totalCarCancelledPilot,
        totalMotoCancelledPilot,
        payModeCount,
        totalCarGains,
        totalMotoGains,
        rechargesAmount,
        carRecharges,
        motoRecharges,
        carRechargesAmount,
        motoRechargesAmount,
      };

      set({
        isLoading: false,
        reportData,
      });
    } catch (error) {
      console.error(error);
      toast.error("Erro ao gerar relatório");
      set({ isLoading: false });
    }
  },
}));
